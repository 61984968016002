import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { ConfigService } from '../service/config.service'
import { MultiSelectNestedFilterComponent } from '../multi-select-nested-filter/multi-select-nested-filter.component';

declare var $:any;

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.css']
})
export class ConfigFormComponent implements OnInit {
	@ViewChildren(MultiSelectNestedFilterComponent) nested_multiselect: QueryList<any>;
	@Input() data:any= {}
	@Input() show_rearrange:boolean = false
	@Input() type:string = "";
	@Input() data_view_config = null;
	@Input() display_type_name:string = ""
    @Output() config_event: EventEmitter<any> = new EventEmitter<any>();
    dropdownSettings:any = {}
    SingledropdownSettings:any = {}
	constructor(
		public configService:ConfigService,
	) { }

	ngOnInit() {
		this.dropdownSettings = this.configService.search_dropdownSettings
		this.SingledropdownSettings = this.configService.search_SingledropdownSettings
	}

	move_up(i) {
		if (i !== 0) {
			[this.data.drilldown_order_data[i - 1], this.data.drilldown_order_data[i]] = [this.data.drilldown_order_data[i], this.data.drilldown_order_data[i - 1]];
		}
	}

	move_down(i) {
		if (  i !== (this.data.drilldown_order_data.length - 1) ) {
			[this.data.drilldown_order_data[i + 1], this.data.drilldown_order_data[i]] = [this.data.drilldown_order_data[i], this.data.drilldown_order_data[i + 1]];
		}
	}

	add_delete(index, case_type) {
		var temp;
		if (case_type == 'used') {
			temp = this.data.drilldown_order_data[index];
			this.data.unused_drilldown_order_data.push(temp);
			this.data.drilldown_order_data.splice(index, 1);
		} else if ( case_type == 'unused' ) {
			temp = this.data.unused_drilldown_order_data[index];
			this.data.drilldown_order_data.push(temp);
			this.data.unused_drilldown_order_data.splice(index, 1);
		}
	}
	onItemSelect(item: any,obj) {
		this.data.filter_data[obj].paramValue = JSON.parse(JSON.stringify(this.data.filter_data[obj].paramValueList.join(';')));
	}
	onSelectAll(items: any,obj) {
		this.data.filter_data[obj].paramValueList = items
		this.data.filter_data[obj].paramValue = JSON.parse(JSON.stringify(this.data.filter_data[obj].paramValueList.join(';')));
	}

 	clear_filter(){
		for (var i = this.data.filter_data.length - 1; i >= 0; i--) {
			if(this.data.filter_data[i].pKey == 'multiselect'){
				const current_nested_multiselect = this.nested_multiselect.find((filter)=> 
				{
					return filter.unique_id === `config_${this.data.filter_data[i].paramName.substring(1)}_${this.data.id}`
				});
				current_nested_multiselect.ToggleAllSelection(false, true);
				this.data.filter_data[i].paramValue = [];
				continue;
			}
			this.data.filter_data[i]['paramValue'] = ''
			if(this.data.filter_data[i]['paramValueList']){
				this.data.filter_data[i]['paramValueList'] = []
			}
		}
 	}

  get_min_max_val(param){
    let paramValue = JSON.parse(JSON.stringify(param['paramValue']))
    let minValue = null
    let maxValue = null
    if(param['validation']){
      minValue = JSON.parse(JSON.stringify(param['validation']['minValue']))
      maxValue = JSON.parse(JSON.stringify(param['validation']['maxValue']))
      if(param['filterType'] == 'Number'){
        paramValue = parseFloat(paramValue)
        if(minValue && minValue != ''){
          minValue = parseFloat(minValue)
        }else{
          minValue = null
        }
        if(maxValue && maxValue != ''){
          maxValue = parseFloat(maxValue)
        }else{
          maxValue = null
        }
      }
    }
    return [paramValue,minValue,maxValue]
  }
 	validate_filter(){
   		let valid = true;
   		$(".inval").hide()
   		for (var i = this.data.filter_data.length - 1; i >= 0; i--) {
			if(this.data.filter_data[i].pKey == 'multiselect'){
				const current_nested_multiselect = this.nested_multiselect.find((filter)=> 
				{
					return filter.unique_id === `config_${this.data.filter_data[i].paramName.substring(1)}_${this.data.id}`
				});
				this.data.filter_data[i].paramValue = current_nested_multiselect.create_nested_multiselect_payload()[0];
				if(this.data.filter_data[i].paramValue.length == 0){
					valid = false;
					$(".mandatory_"+this.data.id+"_"+i).show();
					continue;
				}else{
					$(".mandatory_"+this.data.id+"_"+i).hide();
				}
			}else{
				let temp_data = this.get_min_max_val(this.data.filter_data[i])
					let paramValue = temp_data[0]
					let minValue = temp_data[1]
					let maxValue = temp_data[2]
					
					if(this.data.filter_data[i]['mandatory'] && this.data.filter_data[i]['paramValue'] == ''){
						valid = false
						$(".mandatory_"+this.data.id+"_"+i).show();
						continue;
					}else{
					$(".mandatory_"+this.data.id+"_"+i).hide();
					}
	
				if((minValue || minValue == 0)  && paramValue != '' && paramValue < minValue){
				valid = false
				$(".min_value"+this.data.id+"_"+i).show();
				continue;
				}else{
				$(".min_value"+this.data.id+"_"+i).hide();
				}
	
				if((maxValue || maxValue == 0)  && paramValue != '' && paramValue > maxValue){
				valid = false
				$(".max_value"+this.data.id+"_"+i).show();
				continue;
				}else{
				$(".max_value"+this.data.id+"_"+i).hide();
				}
			}
   		}
   		if(valid){
    		this.config_event.emit({'case': 'apply_filter'});
    		this.close_modal()
   		}
 	}
	close_modal(){
		$("#"+this.type+"_"+this.data.id).modal("hide");
		this.config_event.emit({'case': 'close_filter_modal'});
	}

	open_modal() {
		console.log(this.data.filter_data)
		this.config_event.emit({'case': 'open_filter_modal'});
		$("#"+this.type+"_"+this.data.id).modal();
		$(".inval").hide()
	}

}
