import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  public max_records = 100;
  public whhost = '160.119.252.177';
  public whport = 9088;
  public wh_service_url =  'http://' + this.whhost + ':' + this.whport;
  
  public dbhost = '160.119.252.177';
  public dbport = 5088;
  public db_service_url =  'http://' + this.dbhost + ':' + this.dbport;
  
  public service_host = '160.119.252.177';

  public ddshost = '160.119.252.177';
  public ddsport = 5089;
  public dds_service_url = 'http://' + this.ddshost + ':' + this.ddsport;
  
  public schedulerhost = '160.119.252.177';
  public schedulerport = 5011;
  public scheduler_service_url = 'http://' + this.schedulerhost + ':' + this.schedulerport;
 
  public dds_server_active  = true;

  public skip_spinner_for_services = ['/whapi/getTableColumns','/get_all_tables_and_columns','/get_pipeline_instance_logs','/whapi/job/data','/whapi/all_ao_map'];
  public cache_service = ['/whapi/all_ao_map','/whapi/getTableColumns']; // (New Variable)

  public io_host = '160.119.252.177';
  public io_port = '3018';
  public io_namespace = 'test';
  public io_url = 'http://' + this.io_host + ':' + this.io_port+'/'+this.io_namespace;
  
  public isAdmin = true;
  public services_to_skip = ['/auth'];
  public css_theme = 'basic';
  public external_btn_conf_cases = [
        {
            value_value: 'view_task',
            value_name: 'view_task'
        },
        {
            value_value: 'view_meeting',
            value_name: 'view_meeting'
        },
        {
            value_value: 'view_case',
            value_name: 'view_case'
        }
    ];
  public dynamic_date_options = [
                {key: "Today", value: "Today"},
                {key: "Yesterday", value: "Yesterday"},
                {key: "Tommorrow", value: "Tommorrow"},
                {key: "Current Month Start", value: "Current Month Start"},
                {key: "Current Month End", value: "Current Month End"},
                {key: "Next Month Start", value: "Next Month Start"},
                {key: "Previous Month Start", value: "Previous Month Start"},
                {key: "Previous Month End", value: "Previous Month End"},
                {key: "Current Year", value: "Current Year"},
                {key: "Current Month", value: "Current Month"},
                {key: "Previous Year", value: "Previous Year"},
                {key: "Previous Month", value: "Previous Month"},
                {key: "Next Year", value: "Next Year"},
                {key: "Next Month", value: "Next Month"},
		{key: "Last 3 Month", value: "Last 3 Month"}
        ];

  public system_access_options = [
    {
      value_value: 'Config Access',
      value_name: 'Config Access'
    },
    {
      value_value: 'Internal App',
      value_name: 'Internal App'
    },
    {
      value_value: 'External App',
      value_name: 'External App'
    }
  ];
  public raptor_switch = true;
  public max_query_history_length = 50;

  constructor() { }
}
