import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { EnvService } from 'src/app/services/env.service';
import { UtilityService } from 'src/app/services/utility.service';
import * as font_data from '../../../../assets/css/fonts.json';

declare var $: any;

@Component({
  selector: 'app-external-btn-config',
  templateUrl: './external-btn-config.component.html',
  styleUrls: ['./external-btn-config.component.css']
})
export class ExternalBtnConfigComponent implements OnInit {

  @Input() lg_data_recs = null;
  @Output() external_btn_config_events: EventEmitter<any> = new EventEmitter<any>();

  external_btn_config = {
    input_json: {
      button_label: null,
      button_title: null,
      button_icon: null,
      button_case: null,
      button_type: 'icon',
      key_for_lg_id: null,
    },
    external_btns: [],
    selected_external_btn: null,
    btn_types: [
      {
        value_value: 'icon',
        value_name: 'Icon'
      }, {
        value_value: 'label',
        value_name: 'Label'
      }
    ],
    // extenral_btn_conf_cases: this.env.external_btn_conf_cases
  };
  parsed_fonts_data = [];
  fonts_data: any = (font_data as any).default;
  search_font_name = '';
  font_searched_results = [];

  constructor(
    private env: EnvService,
    private snotifyService: SnotifyService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    this.init_conf();
    this.icons_data_parse();
  }

  icons_data_parse() {
    // this.icon_completer = this.completerService.local(this.fonts_data,'id', 'id' );
    const icon_remove = ["bahai", "caravan", "compress-alt", "express-alt", "expand-alt", "hat-cowboy", "hat-cowboy-side", "mouse", "record-vinyl", "trailer"];
    for (let i = 0; i < this.fonts_data['data'].length; i++) {
      if (this.fonts_data['data'][i].attributes.membership.free.length) {
        if ($.inArray("solid", this.fonts_data['data'][i].attributes.membership.free) !== -1) {
          if ($.inArray(this.fonts_data['data'][i].attributes.id, icon_remove) === -1) {
            const temp_data = {
              "data": '&#x' + this.fonts_data['data'][i].attributes.unicode + ';' + '&nbsp;&nbsp;' + this.fonts_data['data'][i].id,
              "value": this.fonts_data['data'][i].id
            }
            this.parsed_fonts_data.push(temp_data);
          }
        }
      }
    }
    this.font_searched_results = this.parsed_fonts_data;
  }

  search_fonts(event) {
    const val = event.toLowerCase();
    if (val != '') {
      this.font_searched_results = this.parsed_fonts_data.filter((font) => {
        if (font['value'].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      })
    } else {
      this.font_searched_results = this.parsed_fonts_data;
    }
  }

  open_font_search_modal(index) {
    console.log(index)
    this.external_btn_config.selected_external_btn = index;
    $('#external-button-icon-modal').modal();
  }

  close_font_search_modal() {
    this.search_font_name = '';
    this.font_searched_results = this.parsed_fonts_data;
    $('#external-button-icon-modal').modal('hide');
  }

  font_select(font) {
    if (this.external_btn_config.selected_external_btn != null) {
      this.external_btn_config.external_btns[this.external_btn_config.selected_external_btn].button_icon = font.value;
    }
    this.close_font_search_modal();
  }

  remove_icon(index) {
    this.external_btn_config.external_btns[index].button_icon = null;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  populate_config(data) {
    this.external_btn_config.external_btns = data;
  }

  init_conf() {
    this.external_btn_config.external_btns = [];
    this.clear_external_btn_inputs();
    // this.external_btn_config.external_btns.push(this.external_btn_config.input_json);
  }

  clear_external_btn_inputs() {
    this.external_btn_config.input_json.button_label = null;
    this.external_btn_config.input_json.button_title = null;
    this.external_btn_config.input_json.button_icon = null;
    this.external_btn_config.input_json.button_case = null;
    this.external_btn_config.input_json.button_type = 'icon';
    this.external_btn_config.input_json.key_for_lg_id = null;
  }

  add_input_param() {
    this.external_btn_config.external_btns.push(JSON.parse(JSON.stringify(this.external_btn_config.input_json)));
    this.clear_external_btn_inputs();
    console.log(this.external_btn_config.external_btns);
  }

  remove_input_param(index) {
    this.external_btn_config.external_btns.splice(index, 1);
  }

  validate_forms() {
    let valid = true;
    if (!this.external_btn_config.external_btns.length) {
      return valid;
    }
    const external_btn_copy = JSON.parse(JSON.stringify(this.external_btn_config.external_btns));
    for (let i = 0; i < external_btn_copy.length; i++) {
      const btn_type = external_btn_copy[i]['button_type'];
      btn_type == 'icon' ? delete external_btn_copy[i]['button_label'] : delete external_btn_copy[i]['button_icon']; // skip validation based on btn_type
      for (let j in external_btn_copy[i]) {
        if (external_btn_copy[i][j] == null || external_btn_copy[i][j] == '') {
          valid = false;
          break;
        };
      };
      if (!valid) {
        this.snotifyService.error('Fill External Buttons Configuration Mandantory Fields.', this.utilityService.getConfig());
        break;
      }
    };
    return valid;
  }

}
