import { Component, OnInit, Input, OnChanges, SimpleChanges,ViewChild,Output,EventEmitter } from '@angular/core';
import { ConfigService } from '../../config-builder/service/config.service';

@Component({
  selector: 'app-report-manager',
  templateUrl: './report-manager.component.html',
  styleUrls: ['./report-manager.component.css']
})
export class ReportManagerComponent implements OnInit {
	@Input() report_id = -1;
	@Input() hidden_filter_object?: any = {};
	@Input() visible_filter_object?: any = {};
	@Input() refresh_timer?: any = -1;
	@Input() pause_refresh_timer?: any = true;
	@Input() group_child_report?:boolean = false;
	@Output() ext_btn_event: EventEmitter<any> = new EventEmitter<any>();

	report_data:any = {}
	report_download = ''
	has_report = false
	constructor(
		private _configService:ConfigService
	) { }

	ngOnInit() {
	}
	ngOnChanges(changes:SimpleChanges) {
		if(this.report_id != -1){
			this.has_report = false
			this.get_report_data()
		}
	}
	get_report_data(){
		this._configService.get_report_by_id({'id':this.report_id}).subscribe(res => {
			if(res.errCode == 0){
				this.report_data = res.data
				this.report_download = res.data['param_def']['report_download']?res.data['param_def']['report_download']:'direct'
				this.has_report = true
			}
		})
	}


	ext_btn_event_handler(event){
		this.ext_btn_event.emit(event);
	}
}
